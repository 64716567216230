<!--
 * @Description: 新增和修改新闻信息
* @Autor: YuYang
 * @Date: 2024-03-05 16:00:34
 * @LastEditors: YuYang
 * @LastEditTime: 2024-03-05 16:00:34
-->
<template>
	<div class="m20 p20 bg-white">
		<el-form label-position="right" label-width="120px" :model="form" ref="form" size="small" class="mb50">
			<el-form-item label="标题：" prop="name" :verify="{ maxLen: 30 }">
				<el-input v-model="form.name" style="width: 500px"></el-input>
				<div class="f12 f-grey">标题，建议30字以内</div>
			</el-form-item>
			<el-form-item label="简介：" prop="describe" :verify="{ maxLen: 60 }">
				<el-input v-model="form.describe" style="width: 500px"></el-input>
				<div class="f12 f-grey">
					新闻简介，建议60字以内
				</div>
			</el-form-item>

			<el-form-item label="封面：" prop="cover">
				<FaImageUpload v-model="form.cover" :maxCount="1" />
				<div class="f12 f-grey">建议尺寸：800*800像素</div>
			</el-form-item>

			<el-form-item label="图片：" prop="imgList">
				<FaImageUpload v-model="form.imgList" :maxCount="10" />
				<div class="f12 f-grey">
					建议尺寸：800*800像素，你可以拖拽图片调整顺序，最多上传10张，支持多选
				</div>
			</el-form-item>

			<el-form-item label="价格：" prop="price" :verify="{ gte: 0, type: '数字' }" style="display:none;">
				<el-input v-model="form.price" style="width: 150px"></el-input>
			</el-form-item>

			<el-form-item label="划线价：" prop="originalPrice" :verify="{ gte: 0, type: '数字' }" style="display:none;">
				<el-input v-model="form.originalPrice" style="width: 150px"></el-input>
			</el-form-item>

			<!-- <el-form-item
        label="库存："
        prop="inventory"
        :verify="{gte:1,lte:10000,type:'数字'}"
      >
        <el-input
          v-model="form.inventory"
          style="width:150px"
        ></el-input>
      </el-form-item> -->

			<el-form-item label="内容：">
				<FaRichText style="width: 800px" v-model="form.richText" />
			</el-form-item>

		</el-form>

		<div class="operation">
			<el-button size="small" class="f-white bg-theme" @click="submit()">保存新闻</el-button>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import {
		addNews,
		editNews,
		getNewsById
	} from "@/api/news";

	export default {
		created() {
			let that = this;
			let form = that.$route.params; //列表页不应该加载商品详情,如果这个方式只能在列表页也把详情加载处理 


			//如果id不为空则再次读取一次商品信息!
			//console.log(form, form.id);
			if (form.id != null) {
				//console.log('获取新闻详情');
				that.getNews(form.id);
			}else{
				// 价格改为元设置  金额格式化不要注销 有校验会影响保存
				form.price = form.price ? form.price / 100 : 0;
				form.originalPrice = form.originalPrice ? form.originalPrice / 100 : 0;
				that.form = form;
			}
			


		},
		data() {
			return {
				form: {},
			};
		},
		computed: {
			...mapGetters(["project"]),
		},
		methods: {
			// 获取新闻详情
			async getNews(id) {
				let reqData = {
					projectId: this.project.id, //商户号 防止篡改数据
					id: id,
				};

				let {
					status,
					data
				} = await getNewsById(reqData);
				//console.log('新闻详情--->', data);
				let form = data;
				// // 价格改为元设置
				// form.price = form.price ? form.price / 100 : 0;
				// form.originalPrice = form.originalPrice ? form.originalPrice / 100 : 0;
				this.form = form;
			},
			// 提交
			submit() {

				this.$refs["form"].validate((valid) => {
						//console.log('valid---------?', valid);
					if (valid) {
						//console.log('this.form.id---------?', this.form.id);
						this.form.id ? this.edit() : this.add();
					}
				});
			},

			// 新增
			async add() {
				//console.log('新增');
				let data = this.formatParams({
					projectId: this.project.id,
					...this.form,
				});

				let {
					status
				} = await addNews(data);

				if (status == "10000") {
					this.$notify({
						title: "成功",
						message: "新闻发布成功",
						type: "success",
					});
					this.$router.push({
						name: "news-manager"
					});
				}
			},

			// 编辑
			async edit() {
				//console.log('修改');
				let {
					status
				} = await editNews(this.formatParams(this.form));

				if (status == "10000") {
					this.$notify({
						title: "成功",
						message: "新闻修改成功",
						type: "success",
					});
					this.$router.push({
						name: "news-manager"
					});
				}
			},

			formatParams(target) {
				let data = this.$cloneDeep(target);
				// 价格为统一为分
				data.price = data.price * 100;
				data.originalPrice = data.originalPrice * 100;
				return data;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.operation {
		position: fixed;
		bottom: 0px;
		right: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 60px;
		width: 1612px;
		box-shadow: 0 -3px 5px #eee;
		background: #fff;
	}
</style>